import React from 'react';

import './ContentSection.css';
import { Button } from '../../ui';

export interface Item {
    key: string;
    imgSrc: string;
    title: string;
    subtitle: string;
}

interface Props {
    items: Item[];
    onClick: () => void;
}

export const ContentSection: React.FC<Props> = ({ items, onClick }) => (
    <div className="ContentSection">
        {items.map(({ key, imgSrc, title, subtitle }) => (
            <div className="ContentSection-item" key={key}>
                <div className="ContentSection-column">
                    <div className="ContentSection-text">
                        <h3 className="ContentSection-title">
                            {title}
                        </h3>
                        <span className="ContentSection-subtitle">
                            {subtitle}
                        </span>
                    </div>
                    <Button onClick={onClick}>Демо-версия</Button>
                </div>
                <img src={imgSrc} alt="img" className="ContentSection-img" />
            </div>
        ))}
    </div>
);
