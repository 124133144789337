import { Item as FlatGalleryItem } from './components/FlatGallery/FlatGallery';
import { Item as GridGalleryItem } from './components/GridGallery/GridGallery';
import { Props as Pricing } from './ui/PriceCard/PriceCard';
import { LensIcon, QuestionIcon, TShirtIcon } from './assets';

export const INFO_URL = 'https://infogram.com/sample-report-mens-casual-1hxj48m7e3gl52v?live';

export const marketPlace: FlatGalleryItem[] = [
    {
        key: 'prices',
        title: 'Цены в заданной категории',
        subtitle: 'Определение вашего ценового сегмента, ценовое позиционирование для вашего бренда, поиск лучших ценовых уровней для выбранных товарных групп',
        imgSrc: '/images/prices.png',
    },
    {
        key: 'brands',
        title: 'Бренды',
        subtitle: 'Определение вашего ценового сегмента, поиск релевантных брендов, сравнение по показателям и ценовым уровням, посадке, цвету и другим атрибутам. Лучшие товары конкурентов по динамике продаж. Определение вашей целевой ниши среди брендов-конкурентов',
        imgSrc: '/images/brands.png',
    },
    {
        key: 'marketplaces',
        title: 'Категории на маркетплейсе',
        subtitle: 'Понимание объема рынка в выбранной категории в деньгах и штуках продаж, динамика цен внутри категории, тренды спроса в деньгах и статистика поиска, перспективные направления для развития, лучшие бренды',
        imgSrc: '/images/marketplaces.png',
    },
    {
        key: 'growth',
        title: 'Поиск ниш для роста',
        subtitle: 'Автоматический анализ, что общего в новых продуктах конкурентов - показ похожих моделей, которые встретились у более чем N конкурентов. Лучшие артикулы по продажам в категории, все харакатеристики лучших артикулов, аналика отзывов (плюсы  и минусы)',
        imgSrc: '/images/growth.png',
    },
];

export const pricing: Pricing[] = [
    {
        key: 'market',
        title: 'Рынок и тренды',
        price: '160 000 ₽',
        researchTheme: 'Исследование рынка по выбранному сегменту и маркетплейсу',
        descriptionItems: [
            'Анализ сегмента рынка, выявление перспективных категорий для инвестиций',
            'Ключевые игроки в сегменте, их доля',
            'Поиск оптимальных цен',
        ],
        postFix: 'Пример сегмента: мужская одежда casual, ваш ценовой сегмент',
    },
    {
        key: 'assortment',
        title: 'Эффективный ассортимент',
        price: '160 000 ₽',
        researchTheme: 'Исследование категорий на маркетплейсе и определение эффективного ассортимента',
        descriptionItems: [
            'Аналитика категории (ключевые показатели подкатегорий)',
            'Тренды по подкатегориям',
            'Устойчивые ценовые уровни в категории',
            'Бренды-конкуренты и их показатели',
            'Динамика сезона',
            'Лучшие артикулы и характеристики',
        ],
    },
    {
        key: 'enterprise',
        title: 'Enterprise',
        price: '500 000 ₽',
        researchTheme: 'Адаптация всех исследований к категорийному дереву заказчика',
        descriptionItems: [
            'Полный анализ вашего рынка',
            'Все маркетплейсы + e-com сайты конкурентов (отдельная тарификация)',
            'Эффективный ассортимент в разрезах, указаных клиентом',
            'Дорожная карта и рекомендации для бренда',
        ],
    },
];

export const useCases: FlatGalleryItem[] = [
    {
        key: 'growth',
        title: 'Выбор ниши для роста',
        subtitle: 'Мы хотим расширить ассортимент мужской одежды casual через категории, в которые выйти эффективнее всего',
        imgSrc: '/images/growth-case.png',
    },
    {
        key: 'levels',
        title: 'Ценовые уровни',
        subtitle: 'Нас интересуют эффективные цены для верхней одежды в нашем ценовом сегменте',
        imgSrc: '/images/levels-case.png',
    },
    {
        key: 'competitors',
        title: 'Конкуренты',
        subtitle: 'Мы хотим знать, кто наши конкуренты в верхней одежде, их цены и ассортимент',
        imgSrc: '/images/competitors-case.png',
    },
    {
        key: 'assortment',
        title: 'Ассортимент',
        subtitle: 'Мы хотим знать, какие товарные группы пользуются спросом у конкурентов, особенно те, которые мы не производим',
        imgSrc: '/images/assortment-case.png',
    },
];

export const whyWe: GridGalleryItem[] = [
    {
        key: 'experts',
        title: 'Эксперты в моде',
        subtitle: 'В отличие от конкурентов, мы знаем особенности рынка моды: тренды, размеры, цвета, посадка и другие вещи, которыхе вы не найдете у конкурентов',
        icon: TShirtIcon,
    },
    {
        key: 'brand',
        title: 'Внимание к бренду клиента',
        subtitle: 'Каждый бренд уникален: мы проводим все исследования с учетом позиционирования бренда и его ассортимента, учитываем уровень цен и конкурентное окружение',
        isBig: true,
        imgSrc: '/images/client-brand-block.png',
    },
    {
        key: 'models',
        title: 'Предсказательные модели',
        subtitle: 'Мы не просто расскажем вам факты, а покажем куда идет тренд на маркетплейсе, сопоставим с трендами поиска и внешними тренд-агенствами (WGSN и др)',
        icon: LensIcon,
    },
    {
        key: 'data',
        title: 'Глубина данных',
        subtitle: 'Мы обращаем внимание на то, что важно для мира моды: цвета, размеры, материалы, посадку, размерные сетки, отзывы покупателей',
        imgSrc: '/images/deep-data-block.png',
    },
    {
        key: 'nuance',
        title: 'Учёт всех нюансов',
        subtitle: 'Мы адаптируем наши исследования под нужды самых крупных заказчиков, под запрос адаптируя данные под иерархию заказчика и его особенности',
        icon: QuestionIcon,
    },
];
