import cn from 'classnames';
import React, { PropsWithChildren, ReactNode } from 'react';
import ModalComponent, { RenderModalBackdropProps } from 'react-overlays/Modal';

import './Modal.css';
import { IconClose } from '../../assets';

interface Props extends PropsWithChildren {
    isOpen: boolean;
    onClose: () => void;
    title?: ReactNode;
    footer?: ReactNode;
    className?: string;
}

export const Modal: React.FC<Props> = ({
    isOpen,
    title,
    footer,
    onClose,
    className,
    children,
}) => {
    const renderBackdrop = (props: RenderModalBackdropProps) => <div {...props} className="Modal-backdrop" />;

    if (!isOpen) {
        return null;
    }

    return (
        <ModalComponent
            show={isOpen}
            onHide={onClose}
            renderBackdrop={renderBackdrop}
            onBackdropClick={onClose}
            className={cn('Modal', className)}
        >
            <>
                {title && (
                    <div className="Modal-header">
                        {title}
                        <IconClose className="Modal-close" onClick={onClose} />
                    </div>
                )}
                <div className="Modal-body">
                    {children}
                </div>
                {footer && (
                    <div className="Modal-footer">
                        {footer}
                    </div>
                )}
            </>
        </ModalComponent>
    )
};
