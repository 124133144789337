import cn from 'classnames';
import React from 'react';

import './Button.css';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    full?: boolean;
    disableHover?: boolean;
}

export const Button: React.FC<Props> = ({ full, disableHover, children, className, ...props }) => (
    <button
        {...props}
        className={cn('Button', full && 'Button-full', !disableHover && 'Button-hover', className)}
    >
        {children}
    </button>
);
