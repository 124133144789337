import React from 'react';

import './FlatGallery.css';

export interface Item {
    key: string;
    imgSrc: string;
    title: string;
    subtitle: string;
}

interface Props {
    items: Item[];
}

export const FlatGallery: React.FC<Props> = ({ items }) => (
    <div className="FlatGallery">
        {items.map(({ key, imgSrc, title, subtitle }) => (
            <div className="FlatGallery-item" key={key}>
                <img src={imgSrc} alt="img" className="FlatGallery-img" />
                <div className="FlatGallery-text">
                    <span className="FlatGallery-title">{title}</span>
                    <span className="FlatGallery-subtitle">{subtitle}</span>
                </div>
            </div>
        ))}
    </div>
);
