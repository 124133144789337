import React from 'react';

import { IconCheck } from '../../assets';
import { INFO_URL } from '../../App.config';
import { Button } from '../Button/Button';

import './PriceCard.css';

export interface Props {
    key: string;
    title: string;
    price: string;
    researchTheme: string;
    descriptionItems: string[];
    postFix?: string;
}

export const PriceCard: React.FC<Props> = ({ title, price, researchTheme, descriptionItems, postFix }) => (
    <div className="PriceCard" onClick={() => window.open(INFO_URL, '_self')}>
        <div className="PriceCard-header">
            <span className="PriceCard-text">{title}</span>
            <span><strong className="PriceCard-text-bold">{price}</strong>&nbsp;<span className="PriceCard-text">за сезон</span></span>
            <span className="PriceCard-text">{researchTheme}</span>
        </div>
        <div className="PriceCard-divider" />
        <div className="PriceCard-content">
            <ul className="PriceCard-list">
                {descriptionItems.map((item, index) => (
                    <li className="PriceCard-list-element" key={index}>
                        <div className="PriceCard-dot">
                            <IconCheck className="PriceCard-check" />
                        </div>
                        <span className="PriceCard-text">{item}</span>
                    </li>
                ))}
                {postFix}
            </ul>
        </div>
        <Button full disableHover>Выбрать</Button>
    </div>
);
