import React, { useState } from 'react';

import { BurgerMenu, IconClose, Logo, VerticalSeparator } from '../../assets';

import './Header.css'
import cn from 'classnames';

export const Header: React.FC = () => {
    const [isMenuActive, setMenuActive] = useState<boolean>(false);

    const handleOpenMenu = () => setMenuActive(true);
    const handleCloseMenu = () => setMenuActive(false);

    return (
        <header id="header" className={cn('Header', isMenuActive && 'Header-active')}>
            <div className="Header-heading">
                <a href="/" className="Header-logo">
                    <Logo />
                </a>
                {isMenuActive ?
                    (
                        <IconClose
                            onClick={handleCloseMenu}
                            className="Header-menu"
                        />
                    ) : (
                        <BurgerMenu
                            onClick={handleOpenMenu}
                            className="Header-menu"
                        />
                    )}
            </div>
            <VerticalSeparator className="Header-separator" />
            <nav className="Header-navigation">
                <a href="#research" onClick={handleCloseMenu} className="Header-link">Исследования</a>
                <a href="#cases" onClick={handleCloseMenu} className="Header-link">Кейсы</a>
                <a href="#why" onClick={handleCloseMenu} className="Header-link">О нас</a>
                <a href="#prices" onClick={handleCloseMenu} className="Header-link">Цены</a>
                <a href="#trial" onClick={handleCloseMenu} className="Header-link">Пробный период</a>
            </nav>
        </header>
    )
};
