import cn from 'classnames';
import React from 'react';

import './Input.css';

interface Props extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange'
> {
    onChange: (value: string | number) => void;
}

export const Input: React.FC<Props> = ({ children, className, onChange, ...props }) => (
    <input
        {...props}
        onChange={(event) => onChange(event.target.value)}
        className={cn('Input', className)}
    >
        {children}
    </input>
);
