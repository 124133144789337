import React, { useState } from 'react';

import { INFO_URL } from '../../App.config';
import { Modal, Input, Button } from '../../ui';

import { Field, Contact, Props } from './ContactModal.types';

const fields: Field[] = [
    { key: 'name', placeholder: 'Имя' },
    { key: 'phone', placeholder: 'Телефон' },
    { key: 'email', placeholder: 'Бизнес почта' },
    { key: 'companyName', placeholder: 'Название компании' },
    { key: 'country', placeholder: 'Страна' },
    { key: 'position', placeholder: 'Ваша должность' },
]

export const ContactModal: React.FC<Props> = ({ isOpen, onClose }) => {
    const [contact, setContact] = useState<Contact>({
        companyName: undefined,
        country: undefined,
        email: undefined,
        name: undefined,
        phone: undefined,
        position: undefined
    });

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Оставьте заявку, и вы сразу получите демо-доступ к отчетности по категории"
            footer={<Button onClick={() => window.open(INFO_URL, '_self')} full>Получить демо-доступ</Button>}
        >
            {fields.map(({ key, ...props }) => (
                <Input
                    key={key}
                    value={contact[key]}
                    onChange={(value) => setContact(prevState => ({ ...prevState, [key]: value}))}
                    {...props}
                />
            ))}
        </Modal>
    )
};
