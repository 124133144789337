import cn from 'classnames';
import React, { HTMLAttributes } from 'react';

import { VerticalSeparatorWithGradient } from '../../assets';

import './Section.css';

interface Props extends HTMLAttributes<HTMLElement> {}

export const Section: React.FC<Props> = ({ children, title, className, ...props }) => (
    <section {...props} className={cn('Section', className)}>
        <VerticalSeparatorWithGradient className="Section-separator" />
        <div className="Section-tab">{title}</div>
        {children}
    </section>
)
