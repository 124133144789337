import React, { useState } from 'react';

import { pricing, useCases, whyWe, marketPlace } from './App.config';
import { ContactModal, ContentSection, FlatGallery, Footer, GridGallery, Header } from './components';
import { Button, PriceCard, Section } from './ui';

import './App.css';

function App() {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
      <div className="App">
          <Header />
          <main className="App-main">
              <section className="Research-section">
                  <h1 className="Research-title">Исследуем рынок и помогаем вашему бренду расти</h1>
                  <h4 className="Research-subtitle">AI-помощник для поиска точке роста вашего продукта и увеличения продаж и прибыли</h4>
                  <Button onClick={handleOpenModal}>Демо-версия</Button>
                  <div className="Research-image" />
              </section>
              <Section id="research" title="Исследуем рынок на маркетплейсах" className="Marketplace-section">
                  <span className="Section-subtitle Marketplace-subtitle">
                      Проводим исследования для вашего бренда на<br />
                      <strong>Lamoda, OZON, Wildberries и Яндекс Маркет</strong>
                  </span>
                  <ContentSection items={marketPlace} onClick={handleOpenModal} />
              </Section>
              <Section id="cases" title="Кейсы использования" className="Cases-section">
                  <span className="Section-subtitle">
                      Как используют наши клиенты наш продукт<br />
                      <strong>Ключевые решения</strong>
                  </span>
                  <FlatGallery items={useCases} />
              </Section>
              <Section id="why" title="Почему именно мы" className="Cases-section">
                  <span className="Section-subtitle">
                      Мы делаем аналитику только<br />
                      для <strong>fashion</strong> бизнеса, поэтому мы в этом лучшие
                  </span>
                  <GridGallery items={whyWe} />
              </Section>
              <Section id="prices" title="Цены" className="Prices-section">
                  <span className="Section-subtitle Prices-subtitle">
                      Укрепите ваше положение на рынке, уверенно{' '}
                      <strong>наращивайте выручку</strong> и <strong>снижайте уровень остатков</strong>{' '}
                      в конце сезона
                  </span>
                  <div className="Prices-content">
                      {pricing.map((item) => <PriceCard {...item} key={item.key} />)}
                  </div>
              </Section>
              <Section id="trial" title="Пробный период" className="Cases-section">
                  <span className="Section-subtitle">
                      Укрепите ваше положение на рынке, уверенно{' '}
                      <strong>наращивайте выручку</strong> и <strong>снижайте уровень остатков</strong>{' '}
                      в конце сезона
                  </span>
                  <Button onClick={handleOpenModal}>Демо-версия</Button>
              </Section>
          </main>
          <Footer />
          <ContactModal isOpen={isModalOpen} onClose={handleCloseModal} />
      </div>
  );
}

export default App;
